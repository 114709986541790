import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import InfoBackground from '../../../components/infoBackground/infoBackground';
import style from './passInfo.module.css';
import PassInfoForm, { passInfoFormData } from './passInfoForm';

const EDIT_PASS_INFO = gql`
mutation EditPassInfo(
  $newPassInfo: PassInfoInput!, 
  $passInfoId: String!
) {
  setPassInfo (
    newPassInfo: $newPassInfo, 
    passInfoId: $passInfoId
  ) {
    success
    error
  }
}
`;

type Props = {
  communityName?: string,
  portalName?: string,
  stepNum?: number,
}

export default function PassInfo(props: Props) :ReactElement {
  const {
    communityName, portalName, stepNum,
  } = props;
  const history = useHistory();
  const { registrationId } = useParams<{ registrationId: string }>();
  const [changeData, setChangeData] = useState<passInfoFormData>({});
  const editChangeData = (
    key: keyof passInfoFormData,
    val: passInfoFormData[keyof passInfoFormData],
  ) => {
    setChangeData((prevData) => ({
      ...prevData,
      [key]: val,
    }));
  };

  const [doEdit, { error: editError }] = useMutation(EDIT_PASS_INFO);
  const submitHandler = () => {
    doEdit({
      variables: {
        newPassInfo: { ...changeData },
        passInfoId: registrationId,
      },
    });
    if (portalName?.toLowerCase() !== 'vendor') {
      history.push(`/pass-builder/${registrationId}/user-info`);
    } else {
      history.push(`/pass-builder/${registrationId}/employee-info`);
    }
  };

  return (
    <div className={style.container}>
      <InfoBackground
        title="Pass Builder"
        showBackBtn
        showPosition
        communityName={communityName}
        portalName={portalName}
      >
        <PassInfoForm
          registrationId={registrationId}
          showContinueBtn
          changeData={changeData}
          editChangeData={editChangeData}
          submitHandler={submitHandler}
          stepNum={stepNum}
        />
      </InfoBackground>

    </div>
  );
}
PassInfo.defaultProps = {
  communityName: undefined,
  portalName: undefined,
  stepNum: undefined,
};
